import { NodeIdAnswersResolver } from '@breathelife/questionnaire-engine';
import { PayerEntity, PayerSelector, ProposedInsuredEntity, Answers } from '@breathelife/types';

import { PartiesEntityGenerator } from './PartiesEntityGenerator';

export class PayerEntityGenerator extends PartiesEntityGenerator<PayerEntity, PayerSelector> {
  readonly proposedInsuredEntity: ProposedInsuredEntity | null;

  constructor(
    answersResolver: NodeIdAnswersResolver,
    entitySelector: PayerSelector,
    proposedInsuredEntity: ProposedInsuredEntity | null
  ) {
    super(answersResolver, entitySelector);
    this.proposedInsuredEntity = proposedInsuredEntity;
  }
  get(answers: Answers): PayerEntity {
    const hasDifferentPayer = this.entitySelector.hasDifferentPayer
      ? this.answersResolver.getAnswer(answers, this.entitySelector.hasDifferentPayer)
      : undefined;

    const branchNumber = this.entitySelector.pad?.branchNumber
      ? this.answersResolver.getAnswer(answers, this.entitySelector.pad?.branchNumber)
      : undefined;

    const financialInstitutionNumber = this.entitySelector.pad?.financialInstitutionNumber
      ? this.answersResolver.getAnswer(answers, this.entitySelector.pad?.financialInstitutionNumber)
      : undefined;
    const accountNumber = this.entitySelector.pad?.accountNumber
      ? this.answersResolver.getAnswer(answers, this.entitySelector.pad?.accountNumber)
      : undefined;

    if (typeof branchNumber !== 'undefined' && typeof branchNumber !== 'string') {
      throw new Error('Error while getting the Payer entity: branchNumber should be a string or undefined');
    }

    if (typeof financialInstitutionNumber !== 'undefined' && typeof financialInstitutionNumber !== 'string') {
      throw new Error(
        'Error while getting the Payer entity: financialInstitutionNumber should be a string or undefined'
      );
    }

    if (typeof accountNumber !== 'undefined' && typeof accountNumber !== 'string') {
      throw new Error('Error while getting the Payer entity: accountNumber should be a string or undefined');
    }

    // FIXME: the logic here should be based on more question than just hasDifferentPayer
    const isSameAsInsured =
      typeof hasDifferentPayer === 'undefined' || (typeof hasDifferentPayer !== 'undefined' && !hasDifferentPayer);
    if (isSameAsInsured) {
      if (!this.proposedInsuredEntity) {
        throw new Error('Error while getting the Payer entity: proposedInsuredEntity should be defined');
      }

      return {
        firstName: this.proposedInsuredEntity.firstName,
        lastName: this.proposedInsuredEntity.lastName,
        email: this.proposedInsuredEntity.email,
        phoneNumber: this.proposedInsuredEntity.phoneNumber,
        address: this.proposedInsuredEntity.address,
        address2: this.proposedInsuredEntity.address2,
        city: this.proposedInsuredEntity.city,
        provinceOrState: this.proposedInsuredEntity.provinceOrState,
        postalCode: this.proposedInsuredEntity.postalCode,
        pad: {
          branchNumber,
          financialInstitutionNumber,
          accountNumber,
        },
      };
    }

    const firstName: unknown = this.answersResolver.getAnswer(answers, this.entitySelector.firstName);
    const lastName: unknown = this.answersResolver.getAnswer(answers, this.entitySelector.lastName);
    const email: unknown = this.entitySelector.email
      ? this.answersResolver.getAnswer(answers, this.entitySelector.email)
      : undefined;
    const phoneNumber: unknown = this.entitySelector.phoneNumber
      ? this.answersResolver.getAnswer(answers, this.entitySelector.phoneNumber)
      : undefined;
    const address: unknown = this.entitySelector.address
      ? this.answersResolver.getAnswer(answers, this.entitySelector.address)
      : undefined;
    const address2: unknown = this.entitySelector.address2
      ? this.answersResolver.getAnswer(answers, this.entitySelector.address2)
      : undefined;
    const city: unknown = this.entitySelector.city
      ? this.answersResolver.getAnswer(answers, this.entitySelector.city)
      : undefined;
    const provinceOrState: unknown = this.entitySelector.provinceOrState
      ? this.answersResolver.getAnswer(answers, this.entitySelector.provinceOrState)
      : undefined;
    const postalCode: unknown = this.entitySelector.postalCode
      ? this.answersResolver.getAnswer(answers, this.entitySelector.postalCode)
      : undefined;

    if (typeof firstName !== 'undefined' && typeof firstName !== 'string') {
      throw new Error('Error while getting the Payer entity: firstName should be a string or undefined');
    }

    if (typeof lastName !== 'undefined' && typeof lastName !== 'string') {
      throw new Error('Error while getting the Payer entity: lastName should be a string or undefined');
    }

    if (typeof email !== 'undefined' && typeof email !== 'string') {
      throw new Error('Error while getting the Payer entity: email should be a string or undefined');
    }

    if (typeof phoneNumber !== 'undefined' && typeof phoneNumber !== 'string') {
      throw new Error('Error while getting the Payer entity: phoneNumber should be a string or undefined');
    }

    if (typeof address !== 'undefined' && typeof address !== 'string') {
      throw new Error('Error while getting the Payer entity: address should be a string or undefined');
    }

    if (typeof address2 !== 'undefined' && typeof address2 !== 'string') {
      throw new Error('Error while getting the Payer entity: address2 should be a string or undefined');
    }

    if (typeof city !== 'undefined' && typeof city !== 'string') {
      throw new Error('Error while getting the Payer entity: city should be a string or undefined');
    }

    if (typeof provinceOrState !== 'undefined' && typeof provinceOrState !== 'string') {
      throw new Error('Error while getting the Payer entity: provinceOrState should be a string or undefined');
    }

    if (typeof postalCode !== 'undefined' && typeof postalCode !== 'string') {
      throw new Error('Error while getting the Payer entity: postalCode should be a string or undefined');
    }

    return {
      firstName,
      lastName,
      email,
      phoneNumber,
      address,
      address2,
      city,
      provinceOrState,
      postalCode,
      pad: {
        branchNumber,
        financialInstitutionNumber,
        accountNumber,
      },
    };
  }
}
