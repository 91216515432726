import { NodeIdAnswersResolver, NodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';
import {
  ProductsEntity,
  OwnerEntity,
  OwnerSelector,
  AdvisorEntity,
  ProposedInsuredEntity,
  ProposedInsuredSelector,
  PayerEntity,
  PayerSelector,
  PaymentSelector,
  Localizable,
  QuoteInfo,
  CollectionInstanceIdentifier,
  Answers,
} from '@breathelife/types';

import { AdvisorEntityGenerator } from './entities/Advisor';
import { OwnerEntityGenerator } from './entities/Owner';
import { PayerEntityGenerator } from './entities/Payer';
import { ProductsEntityGenerator } from './entities/Products';
import { ProposedInsuredEntityGenerator } from './entities/ProposedInsured';
import { InsuranceProduct } from './types/InsuranceProduct';
import { User } from './types/User';

export type EntitySelectors = {
  proposedInsured: ProposedInsuredSelector | null;
  owner: OwnerSelector | null;
  payer: PayerSelector | null;
  payment: PaymentSelector | null;
};

export class InsuranceEntities {
  private readonly answersResolver: NodeIdAnswersResolver;
  private readonly entitySelectors: EntitySelectors;

  constructor(
    nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
    entitySelector: EntitySelectors = {
      proposedInsured: null,
      owner: null,
      payer: null,
      payment: null,
    }
  ) {
    this.answersResolver = new NodeIdAnswersResolver(nodeIdToAnswerPathMap);
    this.entitySelectors = entitySelector;
  }

  getProposedInsured(
    answers: Answers,
    proposedInsuredIndex: CollectionInstanceIdentifier = 0
  ): ProposedInsuredEntity | null {
    if (this.entitySelectors.proposedInsured === null) return null;
    const proposedInsuredEntityGenerator = new ProposedInsuredEntityGenerator(
      this.answersResolver,
      this.entitySelectors.proposedInsured,
      proposedInsuredIndex
    );
    return proposedInsuredEntityGenerator.get(answers);
  }

  // TODO we should consider whether we need to pass the insured people collection instance identifier here
  getOwner(answers: Answers): OwnerEntity | null {
    if (this.entitySelectors.owner === null) return null;
    const ownerEntityGenerator = new OwnerEntityGenerator(this.answersResolver, this.entitySelectors.owner);
    return ownerEntityGenerator.get(answers);
  }

  // The payer entity is generated based on separate answers saved at payer related nodes,
  // with an optional fallback to one of the proposed insured saved in the `insuredPeople` collection
  // if no `payerInsuredPersonIndex` argument is passed, we take the first proposed insured
  getPayer(answers: Answers, payerInsuredPersonIndex: CollectionInstanceIdentifier = 0): PayerEntity | null {
    if (this.entitySelectors.payer === null) return null;

    let proposedInsuredEntity = null;
    if (this.entitySelectors.proposedInsured) {
      const proposedInsuredEntityGenerator = new ProposedInsuredEntityGenerator(
        this.answersResolver,
        this.entitySelectors.proposedInsured,
        payerInsuredPersonIndex
      );
      proposedInsuredEntity = proposedInsuredEntityGenerator.get(answers);
    }

    const payerEntityGenerator = new PayerEntityGenerator(
      this.answersResolver,
      this.entitySelectors.payer,
      proposedInsuredEntity
    );
    return payerEntityGenerator.get(answers);
  }

  getAdvisor(user: User, carrierOrMga?: string): AdvisorEntity {
    const advisorEntityGenerator = new AdvisorEntityGenerator();
    return advisorEntityGenerator.get(user, carrierOrMga);
  }

  getProducts<T extends string | Localizable = Localizable>(
    answers: Answers,
    productsInfo: InsuranceProduct<T>[],
    quotes?: QuoteInfo,
    hideRidersPremium?: boolean
  ): ProductsEntity<T> {
    const productsEntityGenerator = new ProductsEntityGenerator(this.answersResolver);
    return productsEntityGenerator.get(answers, productsInfo, this.entitySelectors.payment, quotes, hideRidersPremium);
  }
}
